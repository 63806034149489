<template>
  <div class="property-title">
    <div class="background">
      <div class="Eng">{{ backgroundTitle }}</div>
      <div class="title">
        {{ title }}
      </div>
      <div class="suggest" v-html="suggest"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundTitle: String,
    title: String,
    suggest: String,
  },
};
</script>
<style lang="less" scoped>
.property-title {
  width: 100%;
  text-align: center;
  .background {
    // width: 1060px;
    // height: 170px;
    margin: 0 auto;
    color: #f6f6f6;
    font-size: 200px;
    position: relative;
    font-weight: 600;
    font-family: "黑体";
    .Eng{
      font-family: 'DIN Alternate Bold';
      font-weight: bold;
      color: #F8F8F8;
      // font-size: 230px;
      // line-height: 268px;
    }
    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      // font-size: 26px;
      font-size: 35px;
      color: #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-weight: 500;
      font-family: "苹方-简 中黑体";
      src: url('../../assets/fonts/PINGFANG HEAVY.TTF');
    }
    .suggest {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      width: 1400px;
      margin: -100px auto 0 auto;
      line-height: 40px;
      p {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
