<template>
  <div class="brand-image">
    <div class="vedio-module">
      <div class="vedio-item" v-for="(item, index) in lists" :key="item.id">
        <div class="img" v-if="index % 2 === 0">
          <img :src="item.photo" class="back-img" />
          <img
            src="../../assets/images/play.png"
            alt="play"
            class="play"
            v-show="!item.videoShow"
            @click="playItem(item, 'video1')"
          />
          <video
            :src="item.video"
            v-show="item.videoShow"
            class="video"
            ref="video1"
            @click="handleVideo(item, 'video1')"
            controls
            controlslist="nodownload"
            disablepictureinpicture="true"
          ></video>
        </div>
        <div
          class="content"
          style="padding: 100px 0 20px 70px"
          v-if="index % 2 === 0"
        >
          <h4>
            {{ item.title }}
          </h4>
          <p>{{ item.description }}</p>
        </div>
        <div
          class="content"
          style="padding: 100px 70px 20px 0"
          v-if="index % 2 != 0"
        >
          <h4>
            {{ item.title }}
          </h4>
          <p>{{ item.description }}</p>
        </div>
        <div class="img" v-if="index % 2 != 0">
          <img :src="item.photo" class="back-img" />
          <img
            src="../../assets/images/play.png"
            alt="play"
            class="play"
            v-show="!item.videoShow"
            @click="playItem(item, 'video2')"
          />
          <video
            :src="item.video"
            v-show="item.videoShow"
            @click="handleVideo(item, 'video2')"
            class="video"
            ref="video2"
            controls
            controlslist="nodownload"
            disablepictureinpicture="true"
          ></video>
        </div>
      </div>
    </div>
    <div class="image-module">
      <div class="three-image-box">
        <div class="image" v-for="(item, index) in showVideo" :key="item.id">
          <div class="img">
            <img :src="item.photo" class="back-img" v-show="!item.videoShow" />
            <video
              :src="item.video"
              v-show="item.videoShow"
              class="video"
              ref="video3"
              @click="handleVideo(item, 'video3')"
              width="100%"
              controls
              controlslist="nodownload"
              disablepictureinpicture="true"
            ></video>
            <img
              src="../../assets/images/play.png"
              alt="play"
              class="play"
              v-show="!item.videoShow"
              @click="playItem(item, 'video3', index)"
            />
          </div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="content">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="check-box">
      <div
        class="check-more"
        v-show="showVideo.length != images.length"
        @click="showVideo = images"
      >
        查看更多
        <img src="../../assets/images/down-gray.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetVideos } from "../../api/index";

export default {
  data() {
    return {
      images: [],
      lists: [],
      showVideo: [],
    };
  },
  computed: {
    publicImages() {},
  },
  methods: {
    playItem(item, name, index = 0) {
      item.videoShow = true;
      this.$refs[name][index].play();
    },
    handleVideo(item, name, index = 0) {
      item.videoShow = true;
      // this.$refs[name][index].pause();
    },
    init() {
      apiGetVideos().then((data) => {
        if (data.data.status === 1) {
          data.data.videos.forEach((item) => {
            item.photo = "http://www.weaiove.com" + item.photo;
            item.video = "http://www.weaiove.com" + item.video;
            item.videoShow = false;
          });
          data.data.videolist.forEach((item) => {
            item.photo = "http://www.weaiove.com" + item.photo;
            item.video = "http://www.weaiove.com" + item.video;
            item.videoShow = false;
          });
          this.lists = data.data.videos;
          this.images = data.data.videolist;
          this.showVideo = data.data.videolist.slice(0, 3);
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.brand-image {
  width: 100%;
  padding: 10px 0;
  .vedio-module {
    width: 100%;
    .vedio-item {
      width: 100%;
      // margin-bottom: 25px;
      display: flex;
      padding: 98px 260px 100px;

      .img {
        width: 695px;
        height: 394px;
        overflow: hidden;
        vertical-align: top;
        position: relative;
        img{
          border-radius: 10px;
        }
        .back-img {
          width: 100%;
          height: 100%;
        }
        .play {
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 2;
          width: 100px;
          height: 100px;
          margin-left: -50px;
          margin-top: -50px;
          cursor: pointer;
        }
        .video {
          width: 100%;
          // height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
        }
      }

      &:nth-child(2n) {
        background: url("../../assets/imgs/bgLine.png") no-repeat top left;
      }
      .content {
        height: 100%;
        flex: 1;
        // padding-top: 100px;
        // padding: 10px 20px 10px 20px;
        h4 {
          font-size: 30px;
          font-family: "苹方-简 中黑体";
          src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
          color: #000;
        }
        p {
          color: #666;
          font-size: 22px;
          line-height: 32px;
          margin-top: 50px;
        }
      }
    }
  }
  .image-module {
    width: 100%;
    // height: 320px;
    padding: 0 160px;
    .three-image-box {
      width: 100%;
      padding-top: 56px;
      .image {
        width: 33.3%;
        // height: 100%;
        padding: 20px;
        display: inline-block;
        .img {
          width: 506px;
          height: 284px;
          overflow: hidden;
          position: relative;
          img{
            border-radius: 10px;
          }
          .back-img {
            width: 100%;
            height: 100%;
          }
          .play {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 2;
            width: 100px;
            height: 100px;
            margin-left: -50px;
            margin-top: -50px;
            cursor: pointer;
          }
        }

        .title {
          font-size: 30px;
          color: #000;
          margin: 21px 0 7px;
          font-weight: 600;
          font-family: "苹方-简 中黑体";
          src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 30px;
          max-height: 60px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 22px;
          color: #666;
          font-family: "苹方-简 常规体";
          src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        }
      }
    }
  }
  .check-box {
    width: 100%;
    padding: 0 160px;
    height: 100px;
    margin-bottom: 85px;
    border-radius: 10px;
    .check-more {
      width: 100%;
      height: 100px;
      background-color: #efefef;
      text-align: center;
      color: #000;
      line-height: 100px;
      font-size: 30px;
      margin-top: 30px;
      cursor: pointer;
      border-radius: 5px;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}
</style>
