<template>
  <div class="brand-image">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <brand-image />
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import BrandImage from "../../components/news/BrandImage";
export default {
  components: {
    PropertyTitle,
    BrandImage,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "VIDEOS",
      title: "品牌影像",
      
    };
  },
};
</script>
<style lang="less" scoped>
.property-title  {
  margin-bottom: 67px;
}</style>
